<template>
  <div>
    <datatables-wrapper
      @optionsChanged="getEvents"
      @updateTable="getEvents"
      :loading="loading"
      :data="events"
      :headers="headers"
      filterType="simple"
      :parameters="['eventListenerName']"
    />
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Devices/Events/DatatablesWrapper.vue";
export default {
  name: "deviceEvents",
  components: {
    DatatablesWrapper,
  },
  watch: {
    device: {
      handler() {
        if (this.device.identifier) this.getEvents({ page: 1 });
      },
    },
  },
  data: () => ({
    headers: [
      {
        text: "Nombre del evento",
        align: "start",
        sortable: false,
        value: "eventListenerName",
      },
      { text: "Tipo del evento", value: "eventListenerType" },
      { text: "Creado en", value: "timestamp" },
      { text: "", value: "data-table-expand" },
      //value = nombre del atributo dentro del array de items
    ],
    loading: false,
    events: {},
    device: {},
  }),

  methods: {
    getEvents(options) {
      this.loading = true;
      let identifier = this.device.identifier;
      if (identifier)
        this.$store
          .dispatch("events/getAllEvents", { identifier, options })
          .then((data) => (this.events = data))
          .finally(() => (this.loading = false));
    },
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        })
        .catch((error) => {
          console.err(error);
        });
    },
  },
  mounted() {
    this.getDevice();
  },
};
</script>

<style>
</style>