<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-content>
          <device-events class="mb-10" />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DeviceEvents from "@/components/Devices/Events/DeviceEvents";
export default {
  components: {
    DeviceEvents,
  },
};
</script>